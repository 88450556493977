.MuiPaper-root {
  zoom: 2;
}
#numpad-text-area-right {
  input {
    text-align: right;
  }
  ::placeholder {
    color: rgba(0, 0, 0, 0.85);
  }
}
#numpad-text-area-center {
  input {
    text-align: center;
  }
}
.numpad-height {
  input {
    height: 32px;
  }
}
.numpad-extra-height {
  input {
    height: 40px;
  }
}
.drawer-numpad {
  input {
    font-size: 24px;
    text-align: right;
  }
}
.sc-htoDjs.qlSAn {
  display: none;
}
