.login-setup-screen {
  background-image: url("../../resources/images/sunrise-login-setup.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

#root,
.login-setup-screen {
  width: 100%;
  height: 100%;
}
