.label-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  height: 100%;
  font-family: Arial, Helvetica, sans-serif;

  .left {
    flex: 1 1 auto;
    overflow: hidden;
    .title {
      font-size: 18px;
      font-weight: bold;
      -webkit-line-clamp: 3;
    }
    .size {
      text-align: left;
    }
    p {
      -webkit-box-orient: vertical;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: normal;
      word-break: break-all;
      margin-bottom: 0;
    }
  }

  .right {
    width: 135px;
    min-width: 135px;
    overflow: hidden;
    text-align: right;
    // .price-dollar {
    //   font-size: 40px;
    //   font-weight: bold;
    //   line-height: 1;
    // }
    // .price-cent {
    //   font-size: 20px;
    //   font-weight: bold;
    //   line-height: 1;
    //   display: inline-block;
    // }
    p {
      -webkit-box-orient: vertical;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: normal;
      word-break: break-all;
      margin-bottom: 0;
      font-size: 12px;
    }
  }
}
