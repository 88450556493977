@import '../utils/Constants';

.toolbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  .toolbar-area {
    display: flex;
  }
  .action {
    padding: 0 12px;
    transition: all 0.3s;
    &:hover {
      background: $pro-header-hover-bg;
    }
  }
  .action-icon-size {
    font-size: 16px;
  }
}

.profile-popover {
  .ant-popover-arrow {
    display: none;
  }
  .profile-popover-width {
    width: 320px;
  }
  .ant-popover-inner-content {
    padding: 32px 0px;
  }
  .ant-space-item {
    width: 100%;
    text-align: center;
  }
  .ant-list-header,
  .ant-list-footer {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.tooltip-remove-arrow {
  .ant-tooltip-arrow {
    display: none;
  }
}

.align-posy-logo {
  .anticon {
    margin-right: 8px;
  }
}

.posy-group-item {
  .ant-menu-item-group-title {
    font-size: 12px;
    font-weight: bold;
    color: white;
    padding-left: 24px;
    padding-bottom: 10px;
  }
  margin-top: 8px;
  margin-bottom: 18px;
}

.posy-group-item-disabled {
  .ant-menu-item-group-title {
    font-size: 12px;
    font-weight: bold;
    padding-left: 24px;
    padding-bottom: 10px;
  }
  margin-top: 8px;
  margin-bottom: 18px;
}

.posy-group-item-hidden {
  .ant-menu-item-group-title {
    display: none;
  }
}

.main-content-area {
  margin: 32px;
}
