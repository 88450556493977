.login-screen {
  background-image: url("../../resources/images/unsplash.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

#root,
.login-screen {
  width: 100%;
  height: 100%;
}
